var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "800px", height: "520px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.layerClose()
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(" " + _vm._s(_vm.$t("msg.SPEC010G010.012"))),
        ]),
        _c(
          "div",
          { staticClass: "content_box" },
          [
            _c("DEXT5Upload", {
              attrs: {
                id: "dextupload1",
                category: "",
                width: "100%",
                height: "200px",
                "file-info": _vm.parentInfo,
                "category-info": [
                  { category: "category1", value: "value1" },
                  { category: "category2", value: "value2" },
                ],
              },
              on: { update: _vm.setFileInfo },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mt10 text_center" }, [
        _c(
          "a",
          {
            staticClass: "button blue lg",
            on: {
              click: function ($event) {
                return _vm.layerClose()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("msg.SPEC010G010.010")))]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }