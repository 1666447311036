<template>
  <div class="popup_wrap" style="width:800px; height:520px;">
    <button
      class="layer_close"
      @click="layerClose()"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">
        {{ $t('msg.SPEC010G010.012') }}<!-- 파일첨부 -->
      </h1>
      <div class="content_box">
        <!--        <h2 class="content_title">Booking</h2>-->
        <DEXT5Upload id="dextupload1"
                     category width="100%" height="200px"
                     :file-info="parentInfo"
                     :category-info="[{ category:'category1',value:'value1'},{ category:'category2',value:'value2'}]"
                     @update="setFileInfo"
        />
      </div>
    </div>
    <div class="mt10 text_center">
      <a class="button blue lg" @click="layerClose()">{{ $t('msg.SPEC010G010.010') }}</a>
    </div>
  </div>
</template>

<script>
import DEXT5Upload from '@/components/DEXT5Upload'
import WrWaiverList from '@/api/rest/settle/wrWaiverList'

export default {
  name: 'EorPicFileUploadPop',
  components: { DEXT5Upload },
  props: {
    parentInfo: {
      type: Array,
      default: function () {
        return []
      }
    },
    popupParams: {
      type: Object,
      default: function () {
        return []
      }
    }
  },
  data: function () {
    return {
      sendFile: {
        blNo: '',
        cntrNo: '',
        fileKndCd: '',
        fileInfo: [{
          fileId: '',
          fileSt: ''
        }]
      }
    }
  },
  watch: {
    parentInfo: function () {
      console.log('parentInfo = ' + JSON.stringify(this.parentInfo))
      //setFileInfo 파일을 올리면 setFileInfo 를 타야하는데 공통에서 뭘 건드렸는지 안타서 코딩추가...
      //const $vm = this
      //$vm.setFileInfo(this.parentInfo)
    }
  },
  created () {
    // test
    //this.fileInfo.guid[i], this.fileInfo.originalName[i], '/', this.fileInfo.size[i], this.fileInfo.fileId[i], uploadId
    //파일조회
    //this.parentInfo = [{ guid: '21915747-F71E-930A-D23F-FD1689A1A8E6', originalName: '스크린샷 2021-08-03 오후 3.22.04.png', size: '16958', fileId: '123', status: 'complete' }]
  },
  mounted () {
    //여기도 조회시 가능
    //this.parentInfo = [{ guid: '21915747-F71E-930A-D23F-FD1689A1A8E6', originalName: '스크린샷 2021-08-03 오후 3.22.04.png', size: '16958', fileId: '123', status: 'complete' }]
    const $vm = this
    $vm.fileList()
  },
  methods: {
    setFileInfo: function (info) {
      console.log('setFileInfo')
      console.log('fileInfo = ' + JSON.stringify(this.parentInfo))
      this.parentInfo = info
      this.popupParams.length = this.parentInfo.length
      this.$emit('fileCheck', this.popupParams)
      console.log('fileInfo = ' + this.parentInfo.length)
      console.log('this.popupParams.blNo = ' + this.popupParams.blNo)
      //file save
      this.sendFile.blNo = this.popupParams.blNo
      this.sendFile.cntrNo = this.popupParams.cntrNo
      this.sendFile.fileKndCd = (this.popupParams.fileKndCd === 'EOR') ? '01' : '02'
      info.forEach((item, idx) => {
      console.log('info===' + JSON.stringify(info))
        if (item.new === true) {
          this.sendFile.fileInfo[idx].fileId = item.fileId
          this.sendFile.fileInfo[idx].fileSt = 'A'
        }
      })
      console.log('this.sendFile===' + JSON.stringify(this.sendFile))
      WrWaiverList.setFileSave(this.sendFile).then(response => {
      console.log('response===' + JSON.stringify(response))
        //this.parentInfo = response.data
      }).catch(err => {
        console.log(err)
      })
      //파일저장
    },
    layerClose: function () {
      console.log('@@@ closw')
      console.log('layerClose().parentInfo = ' + JSON.stringify(this.parentInfo))
      this.$emit('close', this.parentInfo)
      this.$ekmtcCommon.layerClose()
    },
    fileList: function () {
      this.popupParams.fileKind = (this.popupParams.fileKndCd === 'EOR') ? '01' : '02'
      console.log('this.popupParams===' + JSON.stringify(this.popupParams))
      WrWaiverList.getFileList(this.popupParams).then(response => {
      console.log('getFileList.response===' + JSON.stringify(response))
        this.parentInfo = response.data.fileList
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
